
  import { Options, Vue } from 'vue-class-component'; 
  @Options({
    props: {
      msg: String
    }
  })
  export default class Basic extends Vue {
    msg!: string
    STORAGE_KEY = "caprock-local";  
    token = '';
    session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");
    data () {     
      if(this.session){
        var js =this.session;
        this.token=js['access-token'];   
      }else{
        this.token='';  
        this.logout();
      } 
      return {  
        token:this.token
      }
    }    
    created() {    
      return true;
    }
    logout(){
      localStorage.clear(); 
      this.token='';   
      location.href='/sign-in'; 
    } 
  }
