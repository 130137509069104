
    import { Options, Vue } from 'vue-class-component';
    import Basic from '@/components/customer/Basic.vue';
    import Common from '@/services/common.js';
    import moment from 'moment'; 

    import $ from 'jquery';
    import Axios from "axios";
    import Toast from '@/components/Toast.vue'  


    // import HelloWorld from '@/components/HelloWorld.vue';
    // import VueApexCharts from "vue3-apexcharts";
 
    @Options({
        components: { 
            Basic ,
            'toast':Toast
        }
    })

    export default class Product extends Vue { 
        STORAGE_KEY = "caprock-local";   
        user_name = ''; 
        product_accounts   =   []; 
        total_ac_balance    =   0;  
        local_storage:any;
        products = [];
        alerts:any = [];

        form    =   {}

        scrollLoad          =   true; 
        current_page        =   1;
        total_page          =   1;
        last_page           =   0;
        cpount              =   0;
        search              =   '';
        r_product_id:any    =   0;

         
        
        this_month = moment().format("MMM, YYYY");
        prev_month = moment().subtract(1, 'months').startOf('month').format("MMM, YYYY");
         
        

        data() {
            // Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            // this.get_countries();

            return { 
                // download_clicked:this.download_clicked, 
                form: {  
                    product_name           :   '', 
                    product_status         :   1,   
                    product_id             :   '' 
                }  
            }


        }


        created() {     
           

            var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
            if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
                console.log('Try After Sometime');
            }else{ 
                localStorage.removeItem(this.STORAGE_KEY); 
                location.href='/sign-in'; 
            } 
            document.title = "Product || Shree Khodiyar Silver";
            localStorage.setItem(
                'active_page', 'products'
            );  

        }
        mounted(){
            this.user_name=''; 
            // this.get_user_accounts();
            this.get_products(1,'','new');  
        }
        openModal(id: any) {
          $('#' + id).addClass('d-block');
          $('#' + id).addClass('show');
          $('#' + id).css('overflow-y', 'scroll');
          $('#modelBackDrop').removeClass('d-none'); 
        }
        closeModal(id: any,reload=false) {
          $('#' + id).removeClass('d-block');
          $('#' + id).addClass('d-none');
          $('#' + id).removeClass('show');
          $('#modelBackDrop').addClass('d-none'); 
          if(reload==true){
            location.reload();
          }
        } 

        edit_product(product_id){
            this.clean_form();
            var product_list = this.products; 
            var cus :any ;
            for (let i = 0; i <product_list.length; i++) {    
                if(product_list[i]['product_id'] == product_id){
                    cus = product_list[i];
                }  
            }   
            if (cus!=null){
                this.form['product_id']        =   product_id;
                this.form['product_name']      =   cus['product_name']; 
                this.form['product_status']    =   cus['product_status']; 
                this.openModal('product_form');
            } 
        }   
        remove_product(product_id){ 
            if (product_id!=null){  
                this.r_product_id =product_id; 
                this.openModal('remove_item_form');
            } 
        }
        removeProduct(product_id){  

            let form_data = new FormData(); 
            form_data.append('device', 'vuew');
            form_data.append('product_id', product_id);
            form_data.append('user_token',this.local_storage['access-token']); 
             

            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url = Common.base_url()+'remove-product'; 
            Axios.post(url,
                form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent: any) {
                        var loaded: number  =   progressEvent.loaded;
                        var total: number   =   progressEvent.total;
                        var per             =   parseInt((loaded / total * 100).toFixed());
                    }.bind(this)
                }
            )
            .then(response => {
                var title = '';
                console.log(response)
                if (response.data.success == 200) { 
                    title = 'Success'; 
                    alert(response.data.message); 
                } else {
                    title = 'Failed'; 
                    alert(response.data.message); 
                } 
                this.r_product_id =0; 
                this.closeModal('remove_item_form');
                this.get_products(1,'','new');  
            })
            .catch(error => {
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Save');
            }); 
            
        }



        handle_scroll(){ 
           if (this.scrollLoad && this.nearBottomOfPage() ) { 
                this.append_product()
                this.scrollLoad     =   false; 
            }  
        }    
        nearBottomOfPage() {
            return this.scrollDistanceFromBottom() < 1500;
        }
        scrollDistanceFromBottom() {
            return this.pageHeight() - (window.pageYOffset + self.innerHeight);
        }
        pageHeight() {
            return Math.max(document.body.scrollHeight, document.body.offsetHeight);
        } 
        append_product(){
            this.current_page        =   this.current_page+1; 
            if(this.total_page>=this.current_page){
                this.get_products(this.current_page,'','old');   
            } 
        }  
        get_products(page,search,types) {  
            Common.get_products(this.local_storage['access-token'],page,this.search)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        // this.products           =   response.data.products;     
                        var product_list :any   =   response.data.products;  
                        this.scrollLoad = true; 
                        // for (let i = 0; i < product_list.length; i++) {    
                        //     product_list[i].invoice_date = moment(product_list[i].invoice_date).format("DD-MM-YYYY"); 
                            
                        //     if(product_list[i].fine_balance<0){
                        //         product_list[i].fine_balance = '<span style="color:red">'+product_list[i].fine_balance+'</span>';
                        //     }else if(product_list[i].fine_balance==0){
                        //         product_list[i].fine_balance = '<span style="color:black">'+product_list[i].fine_balance+'</span>';
                        //     }else{
                        //         product_list[i].fine_balance = '<span style="color:green">'+product_list[i].fine_balance+'</span>';
                        //     }
                        // }  
                        if(types !='old'){
                            this.current_page           =   response.data.current_page;
                            this.total_page             =   response.data.total_page;
                            this.products               =   product_list;   
                        }else{
                            var append_list  :[]=   response.data.products;  
                            for (let i = 0; i < append_list.length; i++) {    
                                if(this.last_page != 1){
                                    this.products.push(append_list[i]);     
                                }
                            } 
                            if(this.current_page==this.total_page){
                                this.last_page=1;
                            } 
                        }
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        clean_form(){
            var js =JSON.parse(JSON.stringify((this.form)));   
            for (var key in js) {
                this.form[key]='';
            } 
            $('#subBtn').text('Save'); 
        }

        save_product(){
            var js =JSON.parse(JSON.stringify((this.form)));    
            let form_data = new FormData(); 
            form_data.append('device', 'vuew');
            form_data.append('user_token',this.local_storage['access-token']); 
            for (var key in js) {
                form_data.append(key, js[key]);
            }
            form_data.append('product_price','0'); 

            $('#subBtn').attr('disabled');
            $('#subBtn').text('saving.....');

            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url = Common.base_url()+'save-product'; 
            Axios.post(url,
                    form_data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function(progressEvent: any) {
                            var loaded: number = progressEvent.loaded;
                            var total: number = progressEvent.total;
                            var per = parseInt((loaded / total * 100).toFixed());
                        }.bind(this)
                    }
                )
                .then(response => {
                    var title = '';
                    console.log(response)
                    if (response.data.success == 200) {
                      /*  alert(response.data.message);*/
                        title = 'Success';
                        // (this.$refs['toast'] as any).prdipToast( 
                        //     title,
                        //     response.data.message,
                        //     '',
                        //     false
                        // );
                        alert(response.data.message)
                        // setTimeout(() =>this.$router.push('/products'),2000);  
                        this.get_products(1,'','new');  
                        this.closeModal('product_form');
                        this.clean_form();
                    } else {
                        title = 'Failed';
                        // (this.$refs['toast'] as any).prdipToast( 
                        //     title,
                        //     response.data.message,
                        //     '',
                        //     false
                        // );
                        alert(response.data.message);
                        $('#subBtn').removeAttr('disabled');
                        $('#subBtn').text('Save');
                    } 
                })
                .catch(error => {
                    $('#subBtn').removeAttr('disabled');
                    $('#subBtn').text('Save');
                });
        }
    }
