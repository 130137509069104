
    import { Options, Vue } from 'vue-class-component';
    import Basic from '@/components/customer/Basic.vue';
    import Common from '@/services/common.js';
    import moment from 'moment'; 

    // import HelloWorld from '@/components/HelloWorld.vue';
    // import VueApexCharts from "vue3-apexcharts";
 
    @Options({
        components: { 
            Basic 
            // 'apexchart': VueApexCharts
        }
    })

    export default class PendingInvoice extends Vue { 
        STORAGE_KEY = "caprock-local";   
        user_name = ''; 
        customer_accounts   =   []; 
        total_ac_balance    =   0;  
        local_storage:any;
        invoices = [];
        alerts:any = [];
        invoice_status:any =0;

        year_chart_values = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        
        this_month = moment().format("MMM, YYYY");
        prev_month = moment().subtract(1, 'months').startOf('month').format("MMM, YYYY");
         
         
        created() {      
            var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
            if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
                console.log('Try After Sometime');
            }else{ 
                localStorage.removeItem(this.STORAGE_KEY); 
                location.href='/sign-in'; 
            } 
            document.title = "Pending Invoices || Shree Khodiyar Silver";
            localStorage.setItem(
                'active_page', 'pending_invoices'
            );   
            this.invoice_status = 0;
                 
        }
        mounted(){
            this.user_name=''; 
            // this.get_user_accounts();
            this.get_invoices(1,'');  
        }
 
        get_invoices(page,search) {  
            Common.get_invoices(this.local_storage['access-token'],page,search,this.invoice_status)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        this.invoices=response.data.invoices;    

                        var invoice_list :any= this.invoices;  
                        for (let i = 0; i < invoice_list.length; i++) {   
                            
                            invoice_list[i].invoice_date = moment(invoice_list[i].invoice_date).format("DD-MM-YYYY");

                            invoice_list[i].invoice_net_weight = (invoice_list[i].invoice_net_weight).toLocaleString('en-IN', {});
                            

                            if(invoice_list[i].invoice_status==1){
                                invoice_list[i].invoice_status          = 'Complete'; 
                                invoice_list[i].invoice_status_class    = 'btn-bg-light-white font-black font-weight-bold'; 
                            }else{
                                invoice_list[i].invoice_status          = 'Pending';  
                                invoice_list[i].invoice_status_class    = 'btn-bg-light-danger font-danger font-weight-bold'; 
                            }
                        } 
                        this.invoices = invoice_list;

                    }else{
                        this.invoices=[];
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        view_investment_advisor_menu(id) { 
            alert(id)
        } 
    }
