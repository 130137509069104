<template> 
  <router-view></router-view>  
</template> 
<script> 

  import { Options, Vue } from 'vue-class-component';    
  import Common from '@/services/common.js';
  import $ from 'jquery';
  import 'bootstrap'; 
  @Options({
    components: {   
    } 
  })

  export default class App extends Vue { 
    STORAGE_KEY = "caprock-local";  
    token = ''; 
    session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");
    data () {     
      if(this.session){
        var js =this.session;
        this.token=js['access-token'];   
        
      }else{
        this.token='';  
        this.logout();
      } 
      return {  
        token:this.token
      }
    }   

    created() {    
      return true;
    }
    logout(){ 
      localStorage.clear();
      this.token='';   
      location.href='/sign-in'; 
    }
 
 

      
  } 
</script>


