
    import { Options, Vue } from 'vue-class-component';
    import Basic from '@/components/customer/Basic.vue';
    import Common from '@/services/common.js';
    import moment from 'moment'; 
    import $ from 'jquery';
    import Axios from "axios";
    import Toast from '@/components/Toast.vue'  

    // import HelloWorld from '@/components/HelloWorld.vue';
    // import VueApexCharts from "vue3-apexcharts";
 
    @Options({
        components: { 
            Basic,
            'toast':Toast
            // 'apexchart': VueApexCharts
        }
    })

    export default class Transaction extends Vue { 
        STORAGE_KEY = "caprock-local";   
        user_name = ''; 
        customer_accounts   =   []; 
        total_ac_balance    =   0;  
        local_storage:any;
        customer_info   = {};
        transactions    = [];
        customers       = [];
        alerts:any      = [];
        final_balance  :any =  0;
        total_sales:any     =  0;
        total_payments :any =  0;

        customer_id :any=   '';
        form        =   {};

        v_invoice_customer:any  =   "";
        v_invoice_no:any        =   "";
        v_invoice_date:any      =   "";
        v_invoice_weight:any    =   "";
        v_invoice_touch:any     =   "";
        v_invoice_wastage:any   =   ""; 
        v_invoice_fine:any      =   ""; 
 
        this_month = moment().format("MMM, YYYY");
        prev_month = moment().subtract(1, 'months').startOf('month').format("MMM, YYYY");
        

        data() { 
            return {  
                form: {  
                    customer_name           :   '',
                    customer_phone_no       :   '',
                    customer_address        :   '',
                    customer_status         :   1,   
                    customer_id             :   '',    
                    customer_opening_fine   :   ''
                } 
            }
        }
         
        created() {               
            var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
            if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
                console.log('Try After Sometime');
            }else{ 
                localStorage.removeItem(this.STORAGE_KEY); 
                location.href='/sign-in'; 
            } 
            document.title = "Transaction || Shree Khodiyar Silver";
            localStorage.setItem(
                'active_page', 'customers'
            );    
        }

        view_invoice(no,customer,date,weight,touch,wastage,fine){
            this.v_invoice_customer  =   customer;
            this.v_invoice_no        =   no;
            this.v_invoice_date      =   date;
            this.v_invoice_weight    =   weight;
            this.v_invoice_touch     =   touch;
            this.v_invoice_wastage   =   wastage; 
            this.v_invoice_fine      =   fine; 
            this.openModal('view_invoice'); 
        } 

        openModal(id: any) {
          $('#' + id).addClass('d-block');
          $('#' + id).addClass('show');
          $('#' + id).css('overflow-y', 'scroll');
        }
        closeModal(id: any,reload=false) {
          $('#' + id).removeClass('d-block');
          $('#' + id).addClass('d-none');
          $('#' + id).removeClass('show');
          if(reload==true){
            location.reload();
          }
        } 
        mounted(){
            this.user_name='';   
            this.customer_id=this.$route.params.id;   
            this.customer_transactions(this.customer_id);   
        } 
        get_customers(page,search) {  
            Common.get_customers(this.local_storage['access-token'],page,search)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        this.customers=response.data.customers;     
                        var customer_list :any= this.customers;  
                        for (let i = 0; i < customer_list.length; i++) {    
                            if(typeof customer_list[i].last_date !=='undefined' && customer_list[i].last_date!=''){
                                customer_list[i].invoice_date = moment(customer_list[i].last_date).format("DD-MM-YYYY"); 
                            }else{
                                customer_list[i].invoice_date = '-'; 
                            }
                            
                            if(customer_list[i].balance<0){
                                customer_list[i].fine_balance = '<span style="color:black">'+customer_list[i].balance+'</span>';
                            }else if(customer_list[i].balance==0){
                                customer_list[i].fine_balance = '<span style="color:black">'+customer_list[i].balance+'</span>';
                            }else{
                                customer_list[i].fine_balance = '<span style="color:black">'+customer_list[i].balance+'</span>';
                            } 
                        } 
                        this.customers = customer_list; 
                    }
                }).catch(e => {
                  console.log(e)
            }); 
        } 

        customer_transactions(customer_id){
            $('.preloader').removeClass('d-none');
            Common.get_transactions(this.local_storage['access-token'],customer_id)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                        this.customer_info  =   response.data.customer;  
                        this.transactions   =   response.data.transactions;  
 
                        this.final_balance  =    this.transactions[this.transactions.length - 1]['trans_balance'];

                        var trans :any= this.transactions;  
                        for (let i = 0; i < trans.length; i++) {    
                            if(trans[i].trans_type==1){
                                this.total_sales = this.total_sales+trans[i].trans_gross_weight;
                            }else{
                                this.total_payments = this.total_payments+trans[i].trans_net_weight;
                            }



                            if(typeof trans[i].trans_date !=='undefined' && trans[i].trans_date!=''){
                                trans[i].trans_date = moment(trans[i].trans_date).format("DD-MM-YYYY"); 
                            }else{
                                trans[i].trans_date = '-'; 
                            }
                            
                            if(trans[i].trans_balance<0){
                                trans[i].trans_balance = '<span style="color:#black">'+trans[i].trans_balance+'</span>';
                            }else if(trans[i].trans_balance==0){
                                trans[i].trans_balance = '<span style="color:black">'+trans[i].trans_balance+'</span>';
                            }else{
                                trans[i].trans_balance = '<span style="color:black">'+trans[i].trans_balance+'</span>';
                            }

                            if(trans[i].trans_type==1){
                                trans[i].trans_net_weight = '<span style="color:#black">'+trans[i].trans_net_weight+'</span>';
                            }else{
                                trans[i].trans_net_weight = '<span style="color:black">'+trans[i].trans_net_weight+'</span>';
                            } 
                        } 
                        this.transactions       =   trans;  
                        this.total_sales        =   (this.total_sales/1000).toFixed(3) ;
                        this.total_payments     =   (this.total_payments/1000).toFixed(3) ; 
                        this.total_sales        =   this.total_sales.toLocaleString('en-IN', {});
                        this.total_payments     =   this.total_payments.toLocaleString('en-IN', {});
                        
                        this.final_balance      =   (-1*this.final_balance/1000).toFixed(3) ;    
                        this.final_balance      =   this.final_balance.toLocaleString('en-IN', {}); 

                    }else{
                        this.customer_info  =   {};  
                        this.transactions   =   [];  
                    }
                    $('.preloader').addClass('d-none');
                }).catch(e => {
                  console.log(e)
            });
        } 
        
    }
