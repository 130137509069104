import { createApp } from 'vue'
import App from './App.vue' 
import './registerServiceWorker'
import router from './router'
import store from './store'

import Axios from 'axios'
import VueAxios from 'vue-axios'

// import './assets/caprock/css/bootstrap.css';
// import './assets/caprock/css/caprock-c.css';

/*import './assets/caprock/front-css/bootstrap.min.css';
import './assets/caprock/front-css/caprock-style.css';
import './assets/caprock/front-css/custom-css.css';*/

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";  
 

Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
Axios.defaults.baseURL = 'http://sksdata.inlancer.in/'
  
import { GoogleMap, Marker } from 'vue3-google-map'
import vueDebounce from 'vue-debounce';

// import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'; 


// import VueGeolocation from 'vue-browser-geolocation'
// import * as VueGoogleMaps from 'vue2-google-maps'

// Vue.component('', VuePhoneNumberInput);  


createApp(App)
.use(VueAxios,Axios) 
.use(router)   
.use(vueDebounce) 
// .use(VueGeolocation)   
// .use(VueGoogleMaps, {
//   load: {
//     key: 'AIzaSyCJ0LvGGAutrHzyRGHhaNyZfRzqhhvtR00'
//   }
// })
 .component("Marker", Marker)  
 .component("GoogleMap", GoogleMap)  
 .component("font-awesome-icon", FontAwesomeIcon)  
.mount('#app')
