
    import { Options, Vue } from 'vue-class-component';
    import Basic from '@/components/customer/Basic.vue';
    import Common from '@/services/common.js';
    import moment from 'moment'; 
    import $ from 'jquery';
    import Axios from "axios";
    import Toast from '@/components/Toast.vue'  

    // import InfiniteScroll from "infinite-loading-vue3";


    // import HelloWorld from '@/components/HelloWorld.vue';
    // import VueApexCharts from "vue3-apexcharts";
 
    @Options({
        components: { 
            Basic,
            'toast':Toast
            // InfiniteScroll
            // 'apexchart': VueApexCharts
        }
    })

    export default class Customer extends Vue { 
        STORAGE_KEY         = "caprock-local";   
        user_name           = ''; 
        customer_accounts   =   []; 
        total_ac_balance    =   0;  
        local_storage:any;
        customers           = [];
        alerts:any          = [];
        scrollLoad:any      = true;
        form                =   {};
        trendingRepos:any   =   [];
        page                =    1;
        noResult            =   false;
        message             =   "";

        year_chart_values   = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        
        this_month          = moment().format("MMM, YYYY");
        prev_month          = moment().subtract(1, 'months').startOf('month').format("MMM, YYYY");



        current_page        =   1;
        total_page          =   1;
        last_page           =   0;
        cpount              =   0;
        search              =   '';
        record_start_date   =   '';
        record_end_date     =   '';
        t_customer_id:any   =   0;
        t_customer_name:any =   '';
        r_customer_id :any  =   0;

        collection_amount:any =   0;
        

        data() {
            // Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            // this.get_countries();

            return { 
                // download_clicked:this.download_clicked, 
                form: {  
                    customer_name               :   '',
                    customer_phone_no           :   '',
                    customer_address            :   '',
                    customer_status             :   1,   
                    customer_id                 :   '',    
                    customer_opening_fine       :   '',    
                    customer_opening_fine_date  :   '',
                    customer_opening_fine_type  :   1
                }  
            }
        }
         
        created() {               
            var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
            if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
                console.log('Try After Sometime');
            }else{ 
                localStorage.removeItem(this.STORAGE_KEY); 
                location.href='/sign-in'; 
            } 
            document.title = "Customer || Shree Khodiyar Silver";
            localStorage.setItem(
                'active_page', 'customers'
            );  
 
        } 
        async loadDataFromServer(){
            // console.log('call');
            try {
                const result = await Common.get_customers(this.local_storage['access-token'],this.page,'');
                if(result.data.customers.length) {
                  this.trendingRepos.push(...result.data.customers);
                  this.page++;
                  this.scrollLoad = true;

                } else {
                  this.noResult = true;
                  this.message = "No result found";
                }
            } catch(err) {
                this.noResult = true;
                this.message = "Error loading data";
            }
        }

        download_transaction(){
            if(this.t_customer_id!=0 && this.t_customer_name!=''){  
                var customer_id     = this.t_customer_id
                var customer_name   = this.t_customer_name;
                $('.preloader').removeClass('d-none');
                Common.download_transaction(this.local_storage['access-token'],customer_id,this.record_start_date,this.record_end_date)
                    .then(response => { 
                        let blob        = response.data
                        let reader      = new FileReader()
                        reader.readAsDataURL(blob)
                        reader.onload   = (e) => {
                            let a :any  = document.createElement('a')
                            a.download  = customer_name+`.pdf`
                            a.href      = e.target!.result
                            document.body.appendChild(a)
                            a.click()
                            document.body.removeChild(a) 
                            $('.preloader').addClass('d-none');
                        } 
                    }).catch(e => {
                        console.log(e)
                        $('.preloader').addClass('d-none');
                }); 
            }
        }
        download_collection(){
            $('.preloader').removeClass('d-none');
            Common.download_collection(this.local_storage['access-token'])
                .then(response => { 
                    let blob        = response.data 
                    let reader      = new FileReader()
                    console.log(reader);
                    reader.readAsDataURL(blob)
                    reader.onload   = (e) => {
                        let a :any  = document.createElement('a')
                        a.download  = `Collection Report.pdf`
                        a.href      = e.target!.result
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a)  
                        $('.preloader').addClass('d-none');
                    } 
                }).catch(e => {
                    console.log(e)
                    $('.preloader').addClass('d-none');
            });
        }

        openModal(id: any) {
          $('#' + id).addClass('d-block');
          $('#' + id).addClass('show');
          $('#' + id).css('overflow-y', 'scroll');
          $('#modelBackDrop').removeClass('d-none'); 
          
        }
        closeModal(id: any,reload=false) {
          $('#' + id).removeClass('d-block');
          $('#' + id).addClass('d-none');
          $('#' + id).removeClass('show');
          if(reload==true){
            location.reload();
          } 
          $('#modelBackDrop').addClass('d-none'); 
        } 
        mounted(){
            this.user_name          =   '';  
            this.current_page       =   1;
            this.total_page         =   1; 
            this.get_customers(this.current_page,'','new'); 
            this.get_collection_amount();  

        }  
        handle_scroll(){ 
           if (this.scrollLoad && this.nearBottomOfPage() ) { 
                this.append_customer()
                this.scrollLoad = false; 
            }  
        }    
        nearBottomOfPage() {
          return this.scrollDistanceFromBottom() < 1500;
        }
        scrollDistanceFromBottom() {
          return this.pageHeight() - (window.pageYOffset + self.innerHeight);
        }
        pageHeight() {
          return Math.max(document.body.scrollHeight, document.body.offsetHeight);
        } 
        append_customer(){
            this.current_page        =   this.current_page+1; 
            if(this.total_page>=this.current_page){
                this.get_customers(this.current_page,'','old');   
            } 
        }
        get_customers(page,search,types) {  
            Common.get_customers(this.local_storage['access-token'],page,this.search)
                .then(response => { 
                    if (typeof response.data !== 'undefined' && response.data.success == 200 && response.data.customers) { 
                        this.scrollLoad = true; 
                        // this.customers          =   response.data.customers;     
                        var customer_list :any      =   response.data.customers;  
                        this.current_page           =   response.data.current_page;
                        this.total_page             =   response.data.total_page;

                        for (let i = 0; i < customer_list.length; i++) {    
                            if(typeof customer_list[i].last_date !=='undefined' && customer_list[i].last_date!=''){
                                customer_list[i].invoice_date = moment(customer_list[i].last_date).format("DD-MM-YYYY"); 
                            }else{
                                customer_list[i].invoice_date = '-'; 
                            }
                            
                            customer_list[i].balance  = (-1*customer_list[i].balance);
                            if(customer_list[i].balance>0){
                                customer_list[i].fine_balance = '<span style="color:#bd0000">'+customer_list[i].balance+'</span>';
                            }else if(customer_list[i].balance==0){
                                customer_list[i].fine_balance = '<span style="color:black">'+customer_list[i].balance+'</span>';
                            }else{
                                customer_list[i].fine_balance = '<span style="color:green">'+customer_list[i].balance+'</span>';
                            }
                        } 
                        if(types !='old'){
                            this.current_page           =   response.data.current_page;
                            this.total_page             =   response.data.total_page;
                            this.customers              =   customer_list;   
                        }else{
                            var append_list  :[]=   response.data.customers;  
                            for (let i = 0; i < append_list.length; i++) {    
                                if(this.last_page != 1){
                                    this.customers.push(append_list[i]);     
                                }
                            } 
                            if(this.current_page==this.total_page){
                                this.last_page=1;
                            }
                            // console.log((this.customers.length));
                        }
                    }else{
                        if(types=='new'){
                            this.customers = []; 
                        }
                    }
                }).catch(e => {
                  console.log(e)
            });
            
        } 


        get_collection_amount() {  
            Common.get_collection_amount(this.local_storage['access-token'])
                .then(response => { 
                    if (typeof response.data !== 'undefined' && response.data.success == 200 && response.data.collection) {      
                        this.collection_amount           =   response.data.collection;  

                        this.collection_amount     =   (this.collection_amount/1000).toFixed(3) ; 
                        this.collection_amount     =   this.collection_amount.toLocaleString('en-IN', {});
                    }else{
                        this.collection_amount           =   0;  
                    }
                }).catch(e => {
                  console.log(e)
            });
            
        } 

        customer_transactions(customer_id){
            this.$router.push('/customer-transactions/'+customer_id);
        }

        edit_customer(customer_id){
            var customer_list = this.customers; 
            var cus :any ;
            for (let i = 0; i <customer_list.length; i++) {    
                if(customer_list[i]['customer_id'] == customer_id){
                    cus = customer_list[i];
                }  
            }  

            if (cus!=null){
                this.form['customer_id']                =   customer_id;
                this.form['customer_name']              =   cus['customer_name'];
                this.form['customer_address']           =   cus['customer_address'];
                this.form['customer_phone_no']          =   cus['customer_phone_no'];
                this.form['customer_opening_fine']      =   cus['customer_opening_fine'];
                this.form['customer_opening_fine_date'] =   cus['customer_opening_fine_date'];
                this.form['customer_status']            =   cus['customer_status']; 
                this.openModal('customer_form');
            } 
        }
        remove_customer(customer_id){ 
            if (customer_id!=null){  
                this.r_customer_id =customer_id; 
                this.openModal('remove_customer_form');
            } 
        }
        removeCustomer(customer_id){  

            let form_data = new FormData(); 
            form_data.append('device', 'vuew');
            form_data.append('customer_id', customer_id);
            form_data.append('user_token',this.local_storage['access-token']); 
             

            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url = Common.base_url()+'remove-customer'; 
            Axios.post(url,
                form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent: any) {
                        var loaded: number  =   progressEvent.loaded;
                        var total: number   =   progressEvent.total;
                        var per             =   parseInt((loaded / total * 100).toFixed());
                    }.bind(this)
                }
            )
            .then(response => {
                var title = '';
                console.log(response)
                if (response.data.success == 200) { 
                    title = 'Success'; 
                    alert(response.data.message); 
                } else {
                    title = 'Failed'; 
                    alert(response.data.message); 
                } 
                this.r_customer_id =0; 
                this.closeModal('remove_customer_form');
                this.get_customers(1,'','new');  
            })
            .catch(error => {
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Save');
            }); 
            
        }

         
        clean_form(){
            var js =JSON.parse(JSON.stringify((this.form)));   
            for (var key in js) {
                this.form[key]='';
            } 
            $('#subBtn').text('Save'); 
        }
       
        save_customer(){
            var js =JSON.parse(JSON.stringify((this.form)));    
            let form_data = new FormData(); 
            form_data.append('device', 'vuew');
            form_data.append('user_token',this.local_storage['access-token']); 
            for (var key in js) {
                form_data.append(key, js[key]);
            }
            form_data.append('customer_status','1'); 

            $('#subBtn').attr('disabled');
            $('#subBtn').text('saving.....');

            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url = Common.base_url()+'save-customer'; 
            Axios.post(url,
                    form_data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function(progressEvent: any) {
                            var loaded: number = progressEvent.loaded;
                            var total: number = progressEvent.total;
                            var per = parseInt((loaded / total * 100).toFixed());
                        }.bind(this)
                    }
                )
                .then(response => {
                    var title = '';
                    console.log(response)
                    if (response.data.success == 200) {
                      /*  alert(response.data.message);*/
                        title = 'Success';
                        // (this.$refs['toast'] as any).prdipToast( 
                        //     title,
                        //     response.data.message,
                        //     '',
                        //     false
                        // );
                        alert(response.data.message)
                        // setTimeout(() =>this.$router.push('/customers'),2000); 
                        this.get_customers(1,'','new');  
                        this.closeModal('customer_form');
                        this.clean_form();
                    } else {
                        title = 'Failed';
                        // (this.$refs['toast'] as any).prdipToast( 
                        //     title,
                        //     response.data.message,
                        //     '',
                        //     false
                        // );
                        alert(response.data.message);
                        $('#subBtn').removeAttr('disabled');
                        $('#subBtn').text('Save');
                    } 
                })
                .catch(error => {
                    $('#subBtn').removeAttr('disabled');
                    $('#subBtn').text('Save');
                });
        }
    }
