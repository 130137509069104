import Vue from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
 
import Login from '../views/Login.vue'
import Logout from '../views/Logout.vue'
import Home from '../views/Home.vue'
import Customer from '../views/Customer.vue'
import PendingInvoice from '../views/PendingInvoice.vue'
import Product from '../views/Product.vue'
import Payment from '../views/Payment.vue'
import Transaction from '../views/Transaction.vue'
// import Profile from '../views/Profile.vue'
 
const routes: Array<RouteRecordRaw> = [
   
  {
    path: '/',
    name: 'Home',
    component: Home
  }, 
  {
    path: '/pending-invoices',
    name: 'PendingInvoice',
    component: PendingInvoice
  },
  {
    path: '/customer-transactions/:id',
    name: 'Transaction',
    component: Transaction
  },
  {
    path: '/customers',
    name: 'Customer',
    component: Customer
  },
  {
    path: '/products',
    name: 'Product',
    component: Product
  },
  {
    path: '/payments',
    name: 'Payment',
    component: Payment
  },
  // {
  //   path: '/my-profile',
  //   name: 'Profile',
  //   component: Profile
  // }, 
  {
    path: '/sign-in',
    name: 'Login',
    component: Login
  },  
  {
    path: '/sign-out',
    name: 'Logout',
    component: Logout
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
document.title = 'Shree Khodiyar Silver';
 

export default router
