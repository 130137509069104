
  import { Options, Vue } from 'vue-class-component';   
  import Common from '@/services/common.js';  
  import App from './App.vue';  
  import $ from 'jquery';  
  import 'bootstrap';
  import Basic from '@/components/Basic.vue'; // @ is an alias to /src


  // import useVuelidate from '@vuelidate/core';
  // import { required, minLength, email, sameAs,between } from '@vuelidate/validators';

  import Axios from "axios";
  @Options({
    components: {  
      
    },
    /*validations: {
      name: {
        required,
        minLength: minLength(4)
      },
      email: {
        between: between(20, 30)
      }
    }*/
  })

  export default class Logout extends Vue {
    errors : string[]=[];
    err : any[]=[];
    form      ={};
    countries =[];
    states    =[];
    cities    = []; 
    STORAGE_KEY = "caprock-local";  
    session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}");
    data () {    
      Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
    
      return {  
        form: {
          user_email        : '',
          user_password     : ''
        }  
      }
    }   
 
    created() {     
      var js =JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));   
      if(typeof js!=='undefined' && typeof js['access-token']!=='undefined' && js['access-token']!=''){
        this.$router.push('/'); 
      } 
      localStorage.removeItem(this.STORAGE_KEY);  
      this.$router.push('/sign-in');
      return true;
    }
     
      
  } 
