import Axios from 'axios';
Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

var base_url ='';
base_url = 'https://sksdata.inlancer.in/'; 
// base_url = 'http://127.0.0.1:8000/';


class Common {

    base_url(){
        return 'https://sksdata.inlancer.in/'; 
        // return 'http://127.0.0.1:8000/';     
    }
    get_all_countries(data) {
        return Axios.post('/app-country-list', data);
    }

    get_country_states(data) {
        return Axios.post('/app-state-list', data);
    }
    get_terms() {
        var url = base_url+'get-terms'; 
        return Axios.get(url);
    }
    get_privacy() {
        var url = base_url+'get-privacy'; 
        return Axios.get(url);
    }

    get_state_cities(data) {
        return Axios.post('/app-city-list', data);
    }

    get_device_info() {
        var url = base_url+'device-info'; 
        return Axios.get(url);
    }
    generate_unique_id() {
        var unique_id = 12345; 
        return unique_id;
    }

    get_place_search(text) {
        // var config = {
        //     method: 'GET',
        //     url: 'https://maps.googleapis.com/maps/api/place/textsearch/json?query=' + text + '&key=AIzaSyCJ0LvGGAutrHzyRGHhaNyZfRzqhhvtR00',  
        //     headers: {
        //         'Access-Control-Allow-Origin': '*',
        //         'Content-Type': 'application/json;charset=utf-8',
        //     }  
        // };
        var bodyFormData = new FormData();
        bodyFormData.append('place', text); 
        // var url = 'https://maps.googleapis.com/maps/api/place/textsearch/json?query=' + text + '&key=AIzaSyCJ0LvGGAutrHzyRGHhaNyZfRzqhhvtR00';
        // Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'; 
        // return Axios(config);

        var url = base_url+'get-place-list'; 
        return Axios.post(url,bodyFormData);
    }

    get_invoices(token,page=1,search="",status=null) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('page',page); 
        if (status!='-' && status!=null) {
            form_data.append('status',status);  
        }
        form_data.append('search',search); 
        var url = base_url+'invoice-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_dashboard_data(token,start_date,end_date) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        
        form_data.append('start_date',start_date);  
        form_data.append('end_date',end_date);    
        
        var url = base_url+'dashboard-data';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }


    get_transactions(token,customer_id) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('customer_id',customer_id); 
         
        var url = base_url+'customer-transactions';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    download_transactions(token,customer_id) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('customer_id',customer_id); 
         
        var url = base_url+'customer-transactions';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType : 'blob'
        };
        return Axios.post(url,form_data, options);
    }
    download_invoice(token,invoice_id) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('invoice_id',invoice_id); 
         
        var url = base_url+'download-invoice';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType : 'blob' 
        };
        return Axios.post(url,form_data, options);
    }

    download_transaction(token,customer_id,start_date,end_date){
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('customer_id',customer_id); 
        form_data.append('start_date',start_date); 
        form_data.append('end_date',end_date); 
         
        var url = base_url+'download-customer-transactions';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType : 'blob'  
        };
        return Axios.post(url,form_data, options); 
    }
    download_collection(token){
        let form_data = new FormData();
        form_data.append('user_token',token);   
        var url = base_url+'download-collection';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType : 'blob'  
        };
        return Axios.post(url,form_data, options); 
    }


    get_payments(token,page=1,search="",status=null) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('page',page); 
        if (status!='-' && status!=null) {
            form_data.append('status',status);  
        }
        form_data.append('search',search); 
        var url = base_url+'payment-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_customer_balance(token,customer_id) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('customer_id',customer_id);  
        var url = base_url+'customer-balance';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    
    get_products(token,page=1,search="") { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('page',page); 
        form_data.append('search',search); 
        var url = base_url+'product-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_customers(token,page,search) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('page',page); 
        form_data.append('search',search); 
        var url = base_url+'customer-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_collection_amount(token){
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'collection-amount';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    is_token_valid(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'is-token-valid';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_all_customers(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'all-customer-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    get_all_products(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token);  
        var url = base_url+'all-product-list';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }


    get_user_accounts(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        var url = base_url+'customer-accounts';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_doc_details(token) { 
        let form_data = new FormData();
        form_data.append('doc_token',token); 
        var url = base_url+'doc-details';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_link_details(token) { 
        let form_data = new FormData();
        form_data.append('link_code',token); 
        var url = base_url+'link-details';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_user_info(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        var url = base_url+'profile';  
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_documents(token,search) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('search',search); 
        var url = base_url+'customer-docs';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    get_inv_adv_menu(token,inv_id) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('inv_id',inv_id); 
        var url = base_url+'investment-advisor-menu-details';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    participate_inv_adv_menu(token,inv_id) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('inv_id',inv_id); 
        var url = base_url+'participate-investment-advisor-menu';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    
    get_account_detail(token,ac_name) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('ac_name',ac_name); 
        var url = base_url+'customer-account';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    
    get_dashboard(token) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        var url = base_url+'dashboard';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    change_password(token,new_pass,confirm_pass) { 
        let form_data = new FormData();
        form_data.append('user_token',token); 
        form_data.append('new_password',new_pass); 
        form_data.append('confirm_password',confirm_pass); 
        
        var url = base_url+'change-password';  

        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }

    is_approval_valid(approval_id) { 
        let form_data = new FormData();
        form_data.append('approval_id',approval_id);   
        var url = base_url+'approval-data';   
        Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';  
        var options = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }  
        };
        return Axios.post(url,form_data, options);
    }
    
}
export default new Common();