
    import { Options, Vue } from 'vue-class-component';
    import Basic from '@/components/customer/Basic.vue';
    import Common from '@/services/common.js';
    import moment from 'moment'; 


    import $ from 'jquery';
    import Axios from "axios";
    import Toast from '@/components/Toast.vue'  

    import Select2 from 'vue3-select2-component';
    // import HelloWorld from '@/components/HelloWorld.vue';
    // import VueApexCharts from "vue3-apexcharts";
 
    @Options({
        components: { 
            Basic ,
            'toast': Toast,
            'select2':Select2
        }
    })

    export default class Payment extends Vue { 
        STORAGE_KEY = "caprock-local";   
        user_name = ''; 
        customer_accounts   =   []; 
        total_ac_balance    =   0;  
        local_storage:any;
        customers   = [];
        payments    = [];
        alerts:any  = [];
        r_payment_id:any = 0;
        payment_status:any =1;
        customerss   =   [
            {'id':  0, 'text': 'Select Customer'} 
        ]; 


        scrollLoad:any      = true;
        current_page        =   1;
        total_page          =   1;
        last_page           =   0; 
        search              =   '';
        customer_balance:any    =   '';

        form = {};
        
        today = moment().format("YYYY-MM-DD");
        prev_month = moment().subtract(1, 'months').startOf('month').format("MMM, YYYY");
         
        data() {
            // Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            // this.get_countries();

            return { 
                // download_clicked:this.download_clicked, 
                form: {  
                    payment_customer_id     :   0, 
                    payment_fine            :   0,
                    payment_date            :   this.today,
                    payment_notes           :   '',
                    payment_status          :   1,   
                    payment_id              :   '' 
                }  
            }


        }


        created() {      
            var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
            if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
                console.log('Try After Sometime');
            }else{ 
                localStorage.removeItem(this.STORAGE_KEY); 
                location.href='/sign-in'; 
            } 
            document.title = "Payments || Shree Khodiyar Silver";
            localStorage.setItem(
                'active_page', 'payments'
            );   
            this.payment_status = 1;
                 
        }
        mounted(){
            this.user_name=''; 
            // this.get_user_accounts();
            this.get_payments(1,'','new');  
            this.get_customers();
        }

        handle_scroll(){ 
           if (this.scrollLoad && this.nearBottomOfPage() ) {
                this.append_payment()
                this.scrollLoad = false;  
            }  
        }    
        nearBottomOfPage() {
          return this.scrollDistanceFromBottom() < 1500;
        }
        scrollDistanceFromBottom() {
          return this.pageHeight() - (window.pageYOffset + self.innerHeight);
        }
        pageHeight() {
          return Math.max(document.body.scrollHeight, document.body.offsetHeight);
        }

        get_customer_balance(e:any){ 
            Common.get_customer_balance(this.local_storage['access-token'],this.form['payment_customer_id'])
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        var temp_balance   =   response.data.balance;     

                        if(typeof(temp_balance)!=='undefined' && temp_balance!=null&& temp_balance<0){
                            temp_balance = Math.round(temp_balance*(-1))+' DR';
                        }else if(typeof(temp_balance)!=='undefined' && temp_balance!=null&& temp_balance>0){
                            temp_balance = temp_balance+' CR';
                        }  
                        this.customer_balance   =   temp_balance;     
                    }else{
                        this.customer_balance   =   '';     
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        append_payment(){
            this.current_page        =   this.current_page+1; 
            if(this.total_page>=this.current_page){
                this.get_payments(this.current_page,this.search,'old');   
            } 
        }  
 
        get_payments(page,search,types) {  
            Common.get_payments(this.local_storage['access-token'],page,search,this.payment_status)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        // this.payments=response.data.payments;    
                        // var payment_list :any= this.payments;  
                        this.scrollLoad = true; 

                        var payment_list :any= response.data.payments; 
                        for (let i = 0; i < payment_list.length; i++) {   
                            
                            payment_list[i].payment_r_status = payment_list[i].payment_status;
                            payment_list[i].payment_r_date = payment_list[i].payment_date;
                            payment_list[i].payment_date = moment(payment_list[i].payment_date).format("DD-MM-YYYY");

                            payment_list[i].payment_r_fine = (payment_list[i].payment_fine);

                            payment_list[i].payment_fine = (payment_list[i].payment_fine).toLocaleString('en-IN', {});
                            
                            

                            if(payment_list[i].payment_status==1){
                                payment_list[i].payment_status          = 'Complete'; 
                                payment_list[i].payment_status_class    = 'btn-bg-light-white font-black font-weight-bold'; 
                            }else{
                                payment_list[i].payment_status          = 'Pending';  
                                payment_list[i].payment_status_class    = 'btn-bg-light-danger font-danger font-weight-bold'; 
                            }
                        } 
                        if(types !='old'){
                            this.current_page           =   response.data.current_page;
                            this.total_page             =   response.data.total_page;
                            this.payments               =   payment_list;   
                        }else{
                            var append_list  :[]=   response.data.payments;  
                            for (let i = 0; i < append_list.length; i++) {    
                                if(this.last_page != 1){
                                    this.payments.push(append_list[i]);     
                                }
                            } 
                            if(this.current_page==this.total_page){
                                this.last_page=1;
                            }
                            // console.log((this.invoices.length));
                        }
                        // this.payments = payment_list;

                    }else{
                        // this.payments=[];
                        if(types=='new'){
                            this.payments = []; 
                        }
                    } 
                }).catch(e => {
                  console.log(e)
            });
        }

        get_customers() {  
            Common.get_all_customers(this.local_storage['access-token'])
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        this.customers=response.data.customers;   
                        
                        var all_customers = this.customers; 
                        this.customerss   =   [
                            {'id':  0, 'text': 'Select Customer'} 
                        ]; 
                        for (let i = 0; i < all_customers.length; i++) {    
                            var kk = {'id':  all_customers[i]['customer_id'], 'text': all_customers[i]['customer_name']} 
                            this.customerss.push(kk); 
                        }

                    }else{
                        this.customers=[];
                    }
                }).catch(e => {
                  console.log(e)
            });
        }


        openModal(id: any) {
            $('.select2-container').css('width','100%'); 
            $('.select2-selection__rendered').css('line-height','48px');
            $('.select2-selection').css('height','48px'); 
            
            $('#' + id).addClass('d-block');
            $('#' + id).addClass('show');
            $('#' + id).css('overflow-y', 'scroll');
            $('#modelBackDrop').removeClass('d-none'); 
        }
        closeModal(id: any,reload=false) {
            $('#' + id).removeClass('d-block');
            $('#' + id).addClass('d-none');
            $('#' + id).removeClass('show');
            $('#modelBackDrop').addClass('d-none'); 
            if(reload==true){
                location.reload();
            }
        } 

        edit_payment(payment_id){
            this.clean_form();
            var payment_list = this.payments; 
            var cus :any ;
            for (let i = 0; i <payment_list.length; i++) {    
                if(payment_list[i]['payment_id'] == payment_id){
                    cus = payment_list[i];
                }  
            }   
            if (cus!=null){
                this.form['payment_id']                 =   payment_id;
                
                this.form['payment_customer_id']        =   cus['payment_customer_id']; 
                this.form['payment_status']             =   cus['payment_r_status'];  
                this.form['payment_date']               =   cus['payment_r_date'];
                this.form['payment_fine']               =   cus['payment_r_fine'];
                this.form['payment_notes']              =   (cus['payment_notes']!='null' && cus['payment_notes']!=null) ? cus['payment_notes'] : '';  
                
                this.get_customer_balance(0);
                this.openModal('payment_form');
            } 
        }
        remove_payment(payment_id){ 
            if (payment_id!=null){  
                this.r_payment_id =payment_id; 
                this.openModal('remove_payment_form');
            } 
        }
        removePayment(payment_id){   
            let form_data = new FormData(); 
            form_data.append('device', 'vuew');
            form_data.append('payment_id', payment_id);
            form_data.append('user_token',this.local_storage['access-token']); 
             

            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url = Common.base_url()+'remove-payment'; 
            Axios.post(url,
                form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent: any) {
                        var loaded: number  =   progressEvent.loaded;
                        var total: number   =   progressEvent.total;
                        var per             =   parseInt((loaded / total * 100).toFixed());
                    }.bind(this)
                }
            )
            .then(response => {
                var title = '';
                console.log(response)
                if (response.data.success == 200) { 
                    title = 'Success'; 
                    alert(response.data.message); 
                } else {
                    title = 'Failed'; 
                    alert(response.data.message); 
                } 
                this.r_payment_id =0; 
                this.closeModal('remove_payment_form');
                this.get_payments(1,'','new');  
            })
            .catch(error => {
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Save');
            }); 
            
        }

        clean_form(){
            var js =JSON.parse(JSON.stringify((this.form)));   
            for (var key in js) {
                this.form[key]='';
            } 
            this.form['payment_customer_id']        =   0;
            this.form['payment_date']               =   this.today;
            this.form['payment_fine']               =   0;
            this.form['payment_status']             =   1;
            this.customer_balance                   =   '';
            $('#subBtn').text('Save'); 
        }

        save_payment(){
            var error   =   0;
            var js  =   JSON.parse(JSON.stringify((this.form)));    
            let form_data = new FormData(); 
            form_data.append('device', 'vuew');
            form_data.append('user_token',this.local_storage['access-token']); 
            for (var key in js) {
                form_data.append(key, js[key]);
            }
            

            if(this.form['payment_customer_id']<=0){ 
                error=1;
                alert('Please select valid customer');
            }
            if(this.form['payment_fine']<=0){ 
                error=1;
                alert('Please enter valid FINE');
            }
            if(this.form['payment_date'] =='' ||  this.form['payment_date'] ==null){ 
                error=1;
                alert('Please select valid date');
            }
 

            $('#subBtn').attr('disabled');
            $('#subBtn').text('saving.....');

            if(error==0){
                Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                var url = Common.base_url()+'save-payment'; 
                Axios.post(url,
                    form_data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function(progressEvent: any) {
                            var loaded: number = progressEvent.loaded;
                            var total: number = progressEvent.total;
                            var per = parseInt((loaded / total * 100).toFixed());
                        }.bind(this)
                    }
                )
                .then(response => {
                    var title = '';
                    console.log(response)
                    if (response.data.success == 200) {
                      /*  alert(response.data.message);*/
                        title = 'Success';
                        // (this.$refs['toast'] as any).prdipToast( 
                        //     title,
                        //     response.data.message,
                        //     '',
                        //     false
                        // );
                        alert(response.data.message)
                        // setTimeout(() =>this.$router.push('/payments'),2000);  
                        this.get_payments(1,'','new');  
                        this.closeModal('payment_form');
                        this.clean_form();
                    } else {
                        title = 'Failed';
                        // (this.$refs['toast'] as any).prdipToast( 
                        //     title,
                        //     response.data.message,
                        //     '',
                        //     false
                        // );
                        alert(response.data.message);
                        $('#subBtn').removeAttr('disabled');
                        $('#subBtn').text('Save');
                    } 
                })
                .catch(error => {
                    $('#subBtn').removeAttr('disabled');
                    $('#subBtn').text('Save');
                });
            }else{
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Save');
            }

            
        }


    }
