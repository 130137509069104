
    import { Options, Vue } from 'vue-class-component';
    import Basic from '@/components/customer/Basic.vue';
    import Common from '@/services/common.js';
    import moment from 'moment'; 

    import $ from 'jquery';
    import Axios from "axios";
    import Toast from '@/components/Toast.vue'  

    import Select2 from 'vue3-select2-component';
    // import Datepicker from 'vue3-datepicker';



    // import HelloWorld from '@/components/HelloWorld.vue';
    // import VueApexCharts from "vue3-apexcharts";
 
    @Options({
        components: { 
            Basic ,
            'toast': Toast,
            'select2':Select2,
            // 'datepicker':Datepicker
        }
    })

    export default class Home extends Vue { 
        STORAGE_KEY = "caprock-local";   
        user_name = ''; 
        customer_accounts   =   []; 
        total_ac_balance    =   0;  
        local_storage:any;
        invoices    = [];
        customers   = [];
        products    = [];
        r_invoice_id:any = 0;
        productss   =   [
            {'id':  0, 'text': 'Select Product'} 
        ]; 
        customerss   =   [
            {'id':  0, 'text': 'Select Customer'} 
        ]; 
        alerts:any  = [];
        invoice_status:any ='-';
 
        today       = moment().format("YYYY-MM-DD");
        start_date  =   '';
        end_date    =   '';
         
        this_month = moment().format("MMM, YYYY");
        prev_month = moment().subtract(1, 'months').startOf('month').format("MMM, YYYY");
        

        form                =   {};
        customer_count      =   0;
        sales:any           =   0;
        payments:any        =   0;
        p_invoices:any      =   0;
        a_invoices:any      =   0;

        scrollLoad:any      = true;
        current_page        =   1;
        total_page          =   1;
        last_page           =   0;
        cpount              =   0;
        search              =   '';

        v_invoice_customer:any  =   "";
        v_invoice_no:any        =   "";
        v_invoice_date:any      =   "";
        v_invoice_weight:any    =   "";
        v_invoice_touch:any     =   "";
        v_invoice_wastage:any   =   ""; 
        v_invoice_fine:any      =   ""; 
        

        data() {
            // Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            // this.get_countries();

            return { 
                // download_clicked:this.download_clicked, 
                form: {  
                    invoice_customer_id     :   0, 
                    invoice_product_id      :   0,  
                    invoice_date            :   this.today,
                    invoice_gross_weight    :   '',
                    invoice_touch           :   '',
                    invoice_wastage         :   '',
                    invoice_notes           :   '',
                    invoice_status          :   1,   
                    invoice_id              :   '' 
                } ,
                start_date:this.start_date,
                end_date:this.end_date,
                customer_count:this.customer_count,
                sales:this.sales,
                payments:this.payments,
                p_invoices:this.p_invoices,
                a_invoices:this.a_invoices
            } 
        }



        

        created() {      
            var js =this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));    
            if(typeof js!=='undefined' && typeof js['access-token']!='undefined' ){ 
                console.log('Try After Sometime');
            }else{ 
                localStorage.removeItem(this.STORAGE_KEY); 
                location.href='/sign-in'; 
            } 
            document.title = "Home || Shree Khodiyar Silver";
            localStorage.setItem(
                'active_page', 'home'
            );   
            this.user_name='';  
            this.current_page        =   1;
            this.total_page          =   1; 
            this.get_invoices(this.current_page,'','new');  
            this.get_customers();
            this.get_products();  
            this.get_dash_data(); 
        }
        mounted(){
            this.user_name='';  
            this.current_page        =   1;
            this.total_page          =   1;  
        }


        view_invoice(no,customer,date,weight,touch,wastage,fine){
            this.v_invoice_customer  =   customer;
            this.v_invoice_no        =   no;
            this.v_invoice_date      =   date;
            this.v_invoice_weight    =   weight;
            this.v_invoice_touch     =   touch;
            this.v_invoice_wastage   =   wastage; 
            this.v_invoice_fine      =   fine; 
            this.openModal('view_invoice'); 
        } 
        download_invoice(invoice_id,customer_name){
            // alert(invoice_id); 
            $('.preloader').removeClass('d-none');
            Common.download_invoice(this.local_storage['access-token'],invoice_id)
                .then(response => {  
                    let blob        = response.data;
                    let reader      = new FileReader();
                    reader.readAsDataURL(blob);  
                    reader.onload   = (e) => {
                        let a :any  = document.createElement('a')
                        a.download  = customer_name+'- #'+invoice_id+'.pdf'
                        a.href      = e.target!.result
                        document.body.appendChild(a)
                        a.click()
                        document.body.removeChild(a) 
                        $('.preloader').addClass('d-none');
                    }  
                }).catch(e => {
                    $('.preloader').addClass('d-none');
                    console.log(e)
                }); 
        } 
        get_customers() {  
            Common.get_all_customers(this.local_storage['access-token'])
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        this.customers=response.data.customers;   
                        

                        var all_customers = this.customers; 
                        this.customerss   =   [
                            {'id':  0, 'text': 'Select Customer'} 
                        ]; 
                        for (let i = 0; i < all_customers.length; i++) {    
                            var kk = {'id':  all_customers[i]['customer_id'], 'text': all_customers[i]['customer_name']} 
                            this.customerss.push(kk); 
                        }


                    }else{
                        this.customers=[];
                    }
                }).catch(e => {
                  console.log(e)
            });
        }

        get_dash_data(){ 
            console.log('StartDate : '+moment(this.start_date).format('YYYY-MM-DD'));
             
            /*if (typeof this.start_date!=='undefiend' && this.start_date!=''){
                this.start_date = moment(this.start_date).format('YYYY-MM-DD'); 
            }
            if (typeof this.end_date!=='undefiend' && this.end_date!=''){
                this.end_date   = moment(this.end_date).format('YYYY-MM-DD');
            } */

            Common.get_dashboard_data(this.local_storage['access-token'],this.start_date,this.end_date)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        this.customer_count     =   (response.data.customer_count).toLocaleString('en-IN', {});  
                        
                        this.sales              =   (response.data.sales/1000).toFixed(2);
                        this.sales              =   this.sales.toLocaleString('en-IN', {});  
                        this.payments           =   (response.data.payments/1000).toFixed(2); 
                        this.payments           =   this.payments.toLocaleString('en-IN', {});    
                        
                        this.p_invoices         =   response.data.pending;     
                        this.a_invoices         =   response.data.completed;     
                    }else{
                        this.customer_count     =   0;  
                        this.sales              =   0;  
                        this.payments           =   0;     
                        this.p_invoices         =   0;     
                        this.a_invoices         =   0;     
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
 

        get_products() {  
            Common.get_all_products(this.local_storage['access-token'])
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 
                        this.products=response.data.products;   
                        
                        var all_products = this.products; 
                        this.productss   =   [
                            {'id':  0, 'text': 'Select Product'} 
                        ]; 
                        for (let i = 0; i < all_products.length; i++) {    
                            var kk = {'id':  all_products[i]['product_id'], 'text': all_products[i]['product_name']} 
                            this.productss.push(kk); 
                        }
                    }else{
                        this.products=[];
                    }
                }).catch(e => {
                  console.log(e)
            });  
        }


        handle_scroll(){ 
           if (this.scrollLoad && this.nearBottomOfPage() ) {
                this.append_invoice()
                this.scrollLoad = false;  
            }  
        }    
        nearBottomOfPage() {
          return this.scrollDistanceFromBottom() < 1500;
        }
        scrollDistanceFromBottom() {
          return this.pageHeight() - (window.pageYOffset + self.innerHeight);
        }
        pageHeight() {
          return Math.max(document.body.scrollHeight, document.body.offsetHeight);
        }


        append_invoice(){
            this.current_page        =   this.current_page+1; 
            if(this.total_page>=this.current_page){
                this.get_invoices(this.current_page,this.search,'old');   
            } 
        }  
        get_invoices(page,search,types) {  
            Common.get_invoices(this.local_storage['access-token'],page,search,this.invoice_status)
                .then(response => {
                    if (typeof response.data !== 'undefined' && response.data.success == 200) { 

                        this.scrollLoad = true; 
                        // this.invoices=response.data.invoices;    

                        var invoice_list :any= response.data.invoices;
                        for (let i = 0; i < invoice_list.length; i++) {   
                        
                            invoice_list[i].invoice_r_date        =   invoice_list[i].invoice_date;    
                            invoice_list[i].invoice_r_net_weight  =   invoice_list[i].invoice_net_weight;
                            invoice_list[i].invoice_r_gross_weight=   invoice_list[i].invoice_gross_weight;
                            invoice_list[i].invoice_r_status      =   invoice_list[i].invoice_status;


                            invoice_list[i].invoice_date            = moment(invoice_list[i].invoice_date).format("DD-MM-YYYY"); 
                            invoice_list[i].invoice_net_weight      = (invoice_list[i].invoice_net_weight).toLocaleString('en-IN', {});
                            invoice_list[i].invoice_gross_weight    = (invoice_list[i].invoice_gross_weight).toLocaleString('en-IN', {});
                            

                            if(invoice_list[i].invoice_status==1){
                                invoice_list[i].invoice_status          = 'D'; 
                                invoice_list[i].invoice_status_class    = 'btn-bg-light-white font-black font-weight-bold'; 
                            }else{
                                invoice_list[i].invoice_status          = 'P';  
                                invoice_list[i].invoice_status_class    = 'btn-bg-light-danger font-danger font-weight-bold'; 
                            }
                        } 

                        if(types !='old'){
                            this.current_page           =   response.data.current_page;
                            this.total_page             =   response.data.total_page;
                            this.invoices               =   invoice_list;   
                        }else{
                            var append_list  :[]=   response.data.invoices;  
                            for (let i = 0; i < append_list.length; i++) {    
                                if(this.last_page != 1){
                                    this.invoices.push(append_list[i]);     
                                }
                            } 
                            if(this.current_page==this.total_page){
                                this.last_page=1;
                            }
                            // console.log((this.invoices.length));
                        }

                        // this.invoices = invoice_list;

                    }else{
                        // this.invoices=[];
                        if(types=='new'){
                            this.invoices = []; 
                        }
                    }
                }).catch(e => {
                  console.log(e)
            });
        }
        

        edit_invoice(invoice_id){
            this.clean_form();
            var invoice_list = this.invoices; 
            var cus :any ;
            for (let i = 0; i <invoice_list.length; i++) {    
                if(invoice_list[i]['invoice_id'] == invoice_id){
                    cus = invoice_list[i];
                }  
            }   
            // console.log(cus['invoice_notes']);
            if (cus!=null){
                this.form['invoice_id']                 =   invoice_id;
                this.form['invoice_date']               =   cus['invoice_r_date']; 
                this.form['invoice_customer_id']        =   cus['invoice_customer_id']; 
                this.form['invoice_product_id']         =   cus['invoice_product_id']; 
                this.form['invoice_gross_weight']       =   cus['invoice_r_gross_weight'];
                this.form['invoice_touch']              =   cus['invoice_touch'];
                this.form['invoice_wastage']            =   cus['invoice_wastage'];
                this.form['invoice_notes']              =   (cus['invoice_notes']!='null' && cus['invoice_notes']!=null) ? cus['invoice_notes'] : ''; 
                this.form['invoice_status']             =   cus['invoice_r_status'];  
                
                this.openModal('invoice_form');
            } 
        }
        
        

        openModal(id: any) {
            $('#' + id).addClass('d-block');
            $('#' + id).addClass('show');
            $('#' + id).css('overflow-y', 'scroll');
            $('#modelBackDrop').removeClass('d-none'); 

            $('.select2-container').css('width','100%'); 
            $('.select2-selection__rendered').css('line-height','48px');
            $('.select2-selection').css('height','48px'); 
        }

        closeModal(id: any,reload=false) {
            $('#' + id).removeClass('d-block');
            $('#' + id).addClass('d-none');
            $('#' + id).removeClass('show');
            $('#modelBackDrop').addClass('d-none'); 
            if(reload==true){
                location.reload();
            }
        } 

        clean_form(){
            var js =JSON.parse(JSON.stringify((this.form)));   
            for (var key in js) {
                this.form[key]  =   '';
            } 
            this.form['invoice_customer_id']        =   0;
            this.form['invoice_product_id']         =   0;
            this.form['invoice_date']               =   this.today;
            this.form['invoice_fine']               =   0;
            this.form['invoice_status']             =   1;
            $('#subBtn').text('Save'); 
        }


        remove_invoice(invoice_id){ 
            if (invoice_id!=null){  
                this.r_invoice_id =invoice_id; 
                this.openModal('remove_invoice_form');
            } 
        }
        removeInvoice(invoice_id){   
            let form_data = new FormData(); 
            form_data.append('device', 'vuew');
            form_data.append('invoice_id', invoice_id);
            form_data.append('user_token',this.local_storage['access-token']); 
             

            Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
            var url = Common.base_url()+'remove-invoice'; 
            Axios.post(url,
                form_data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    onUploadProgress: function(progressEvent: any) {
                        var loaded: number  =   progressEvent.loaded;
                        var total: number   =   progressEvent.total;
                        var per             =   parseInt((loaded / total * 100).toFixed());
                    }.bind(this)
                }
            )
            .then(response => {
                var title = '';
                console.log(response)
                if (response.data.success == 200) { 
                    title = 'Success'; 
                    alert(response.data.message); 
                } else {
                    title = 'Failed'; 
                    alert(response.data.message); 
                } 
                this.r_invoice_id =0; 
                this.closeModal('remove_invoice_form');
                this.get_invoices(1,'','new');  
            })
            .catch(error => {
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Save');
            }); 
            
        }
        save_invoice(){
            var error   =   0;
            var js  =   JSON.parse(JSON.stringify((this.form)));    
            let form_data = new FormData(); 
            form_data.append('device', 'vuew');
            form_data.append('user_token',this.local_storage['access-token']); 
            for (var key in js) {
                form_data.append(key, js[key]);
            }
            
            // console.log(js);

            if(this.form['invoice_customer_id']<=0){ 
                error=1;
                alert('Please select valid CUSTOMER');
            }
            if(this.form['invoice_product_id']<=0){ 
                error=1;
                alert('Please select valid PRODUCT');
            }
            if(this.form['invoice_gross_weight']<=0){ 
                error=1;
                alert('Please enter valid Weight');
            }
            if(this.form['invoice_touch']<=0){ 
                error=1;
                alert('Please enter valid TOUCH');
            }
            if(this.form['invoice_date'] =='' ||  this.form['invoice_date'] ==null){ 
                error=1;
                alert('Please select valid date');
            }
 

            $('#subBtn').attr('disabled');
            $('#subBtn').text('saving.....');

            if(error==0){
                Axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
                var url = Common.base_url()+'save-invoice'; 
                Axios.post(url,
                    form_data, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function(progressEvent: any) {
                            var loaded: number = progressEvent.loaded;
                            var total: number = progressEvent.total;
                            var per = parseInt((loaded / total * 100).toFixed());
                        }.bind(this)
                    }
                )
                .then(response => {
                    var title = '';
                    // console.log(response)
                    if (response.data.success == 200) {
                      /*  alert(response.data.message);*/
                        title = 'Success';
                        // (this.$refs['toast'] as any).prdipToast( 
                        //     title,
                        //     response.data.message,
                        //     '',
                        //     false
                        // );
                        alert(response.data.message)
                        // setTimeout(() =>this.$router.push('/payments'),2000);  
                        this.get_invoices(1,'','new');  
                        this.closeModal('invoice_form');
                        this.clean_form();
                    } else {
                        title = 'Failed';
                        // (this.$refs['toast'] as any).prdipToast( 
                        //     title,
                        //     response.data.message,
                        //     '',
                        //     false
                        // );
                        alert(response.data.message);
                        $('#subBtn').removeAttr('disabled');
                        $('#subBtn').text('Save');
                    } 
                })
                .catch(error => {
                    $('#subBtn').removeAttr('disabled');
                    $('#subBtn').text('Save');
                });
            }else{
                $('#subBtn').removeAttr('disabled');
                $('#subBtn').text('Save');
            }

            
        }
    }
