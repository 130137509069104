
  import { Options, Vue } from 'vue-class-component';
  import $ from 'jquery';
  import Common from '@/services/common.js';
  import Toast from '@/components/Toast.vue';  
  import Axios from "axios";  
  import 'bootstrap'; 
  @Options({
    props: {
      msg: String
    },
    components: { 
      'toast':Toast,
    }  
  })
  export default class Basic extends Vue {
    msg!: string
    STORAGE_KEY = "caprock-local";   
    session = JSON.parse(localStorage.getItem(this.STORAGE_KEY) || "{}"); 
    token = '';
    active_page = localStorage.getItem('active_page');  
    user_name = ''; 
    error   = ''; 
    success = ''; 
    form = {
      userac_id :'',
      subac_id  :'',
      ttype      :'all',
      from_date :'',
      to_date   :'',  
    }
    u_accounts:any = [];
    us_accounts:any=[];
    showSlideBar= false; 
    profile_url =localStorage.getItem('profile_url'); 
    b_profile_url ='https://admin.caprock.com.au/inlancer-admin/images/user.png'; 

    local_storage:any;

    data () {     
      if(this.session){
        var js              =    this.session;
        this.token          =   js['access-token'];   
        this.user_name      =   js['user_info']['user_name'];    
      }else{
        this.token='';  
        this.logout();
      } 
      return {  
        token:this.token,
        showSlideBar: this.showSlideBar,
      }

    }    
    created() {      
        this.local_storage=JSON.parse((localStorage.getItem(this.STORAGE_KEY) || "{}"));   

        this.is_token_valid();
        return true; 
    }
    logout(){
      localStorage.removeItem(this.STORAGE_KEY); 
      localStorage.clear();
      this.token='';  
      // this.$router.push('/sign-in');  
      location.href='/sign-in'; 
    } 

    open_report(id){ 
      if (typeof id!=='undefined' && id==1){
        (this.$refs['toast'] as any).openModal('transaction_report'); 
      }
      if (typeof id!=='undefined' && id==2){
        (this.$refs['toast'] as any).openModal('portfolio_report'); 
      } 
    } 
    

    slideBar() {
      this.showSlideBar = !this.showSlideBar;
    }

    mounted(){
      document.body.classList.add('overflow-y-scroll')
    }
    loadImage(){
      this.profile_url = this.b_profile_url;
    }

    is_token_valid() {   
        Common.is_token_valid(this.local_storage['access-token'])
            .then(response => {
                if (typeof response.data !== 'undefined' && response.data.success == 200) {  
                    return true;
                }else{  
                    this.logout();
                } 
            }).catch(e => {
              console.log(e)
        });
    }

    

  }
